.show {
    display: block !important;
}

.dropdown-menu {
    min-width: 10rem;
    top: 100%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    margin: 0;
    margin: 0.125rem 0 0;
    padding: 0.5rem 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: none;
    float: left;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: rgb(42, 48, 66);
    background-clip: padding-box;
    border: 1px solid rgb(50, 57, 78);
    border-radius: 0.25rem;
}

.text-danger {
    color: rgb(239, 35, 35)!important;
}

a.text-danger:focus, a.text-danger:hover {
    color: #ef2323!important;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #e9ecef;
    text-decoration: none;
    background-color: #32394e;
}

.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}

.dropdown-item {
    font-size: 13px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 500;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-menu {
  box-shadow: 0 1rem 3rem rgba(#000000, .175);
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100%!important;
  }
}

.dropdown-menu-right {
  right: 0!important;
  left: auto!important;
}

.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=left]{
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
  0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider{
  border-top-color: #32394e;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static!important;
}

.dropdown-megamenu {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl{
  width: 40rem;
}

.dropdown-mega-menu-lg{
  width: 26rem;
}