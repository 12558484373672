
.card {
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
}
  
.card-drop {
    color: #a6b0cf;
}

.card-title {
    color: #f6f6f6;
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
  
.card-title-desc {
    color: #a6b0cf;
    margin-bottom: 24px;  
}