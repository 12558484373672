.account-pages {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.auth {
    max-width: 27rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #2a3042;
    background-clip: border-box;
    border: 0 solid #32394e;
    border-radius: .25rem;
    margin-bottom: 24px;
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
    overflow: hidden !important;

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        background-color: rgba(85, 110, 230, .25) !important;
        padding: 20px 0;
    }

    &__header-text {
        color: #556ee6 !important;
        padding: 1.5rem !important;

        h5 {
            margin-bottom: .8rem;
            font-size: 1rem;
            color: #f7f7f7;
        }

        p {
            margin-bottom: 1rem;
        }
    }

    &__header-img {
        max-width: 11rem;
    }

    &__body {
        padding-top: 0 !important;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
    }

    &__logo {
        height: 4.5rem;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 2rem;
        margin-bottom: -.75rem;
        border-radius: 50%;
        background-color: #32394e;
    }

    &__logo-img {
        height: 2.1rem;
        width: 2rem;
    }

    &__change-page {
        margin-top: 2rem;
        text-align: center;

        a {
            color: #bfc8e2 !important;
        }
    }
}

.auth-copyright {
    margin-top: 1.5rem !important;
    text-align: center;
}