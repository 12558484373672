.notifications {
    position: relative;

    &__button {
        height: 70px;
        position: relative;
        box-shadow: none !important;
        cursor: pointer;
        display: inline-block;
        border: 0;
        border-radius: 0;
        overflow: hidden;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
        padding: 0.47rem 0.75rem;
        font-size: .8125rem;
        line-height: 1.5;

        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }

        &:hover {
            color: #a6b0cf;
            text-decoration: none;
        }
    }
}

#notifications-list {
    max-height: 230px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: .6rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(#a6b0cf, .7);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}

.dropdown-menu {
    box-shadow: 0 1rem 3rem rgba(#000, .175);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    color: rgb(191, 200, 226);
    z-index: 1000;

    &.show {
        top: 100% !important;
    }
}

.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}

.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=left] {
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
}

@media (min-width: 600px) {
    .dropdown-menu-lg {
        width: 320px;
    }

    .dropdown-menu-md {
        width: 240px;
    }
}

.dropdown-divider {
    border-top-color: #32394e;
}

// Dropdown Mega Menu

.dropdown-mega {
    position: static !important;
}

.dropdown-megamenu {
    padding: 20px;
    left: 20px !important;
    right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
    width: 40rem;
}

.dropdown-mega-menu-lg {
    width: 26rem;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
}