.footerContainer {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: inherit;
    padding-top: 0px !important;
}

.footer {
    bottom: 0;
    padding: 0px calc(#{60px} / 2);
    position: absolute;
    right: 0;
    color: #a6b0cf;
    left: 250px;
    height: 60px;
    background-color: #262b3c;
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

.vertical-collpsed {
    .footer {
        left: 70px;
    }
}

.main-container[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}